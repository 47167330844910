.postBox {
    border: 2px solid black;
    border-radius: 1rem;
    width: 40rem;
    margin: 1rem;
    justify-content: space-between;
    box-shadow: 5px 5px;
    background-color: white;
}

.buttons {
    display: flex;
    flex-direction: row;
}

.upDownButtons, input {
    padding: 0.5rem;
    margin: 0.5rem;
    font-size: 1.25rem;
    box-shadow: 2px 2px;
    border-radius: 5px;
}

span {
    padding: 0.25rem;
    margin: 0.25rem;
    font-size: 2rem;
}

.feed h2 {
    margin: 1rem;
}

.feed p {
    margin: 1rem;
    padding: 0.5rem;
    width: 90%;
    word-wrap: break-word;
}

.feed video, img {
    width: 90%;
    border-radius: 10px;
}

.feedNum {
    font-size: 2rem;
    padding: 0 1rem;
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .postBox {
        width: 20rem;
    }
}