div {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.titleWrapper {
    background-color: white;
    border: 2px solid black;
    border-radius: 1rem;
    width: 40rem;
    margin: 1rem;
    padding-bottom: 2rem;
    box-shadow: 5px 5px;
    max-width: 90vw;
    text-align: center;
}

h1 {
    padding: 0.75rem;
    font-weight: light;
}

h3 {
    padding: 0 1rem;
    font-weight: lighter;
}

h5 {
    padding: 0 1rem;
    margin: .25rem;
}